<template>
  <div class="p-grid">
    <Card class="style p-col">
      <template #title>
        <div class="p-d-flex p-jc-between">
          <div>
            <h3 class="p-m-0">
              <strong>{{ _t('label_reports') }}</strong>
            </h3>
          </div>
        </div>
      </template>
      <template #content>
        <TabView :activeIndex="activeTab">
          <TabPanel :header="_t('menu.detailed_reports')">
            <reportDetailed/>
          </TabPanel>
          <TabPanel :header="_t('menu.monthly_reports')">
            <reportMonthly/>
          </TabPanel>
        </TabView>
      </template>
    </Card>
  </div>
</template>

<script>
import reportMonthly from './monthly';
import reportDetailed from './detailed';

export default {
  name: 'reports-page',
  components: {
    reportMonthly,
    reportDetailed,
  },
  data: () => ({
    activeTab: 0,
  }),
  mounted() {
    switch (this.$route.params.tab.toLowerCase()) {
      case 'monthly':
        this.activeTab = 1;
        break;
      default:
        this.activeTab = 0;
    }
  },
  watch: {
    '$route.params.tab': function () {
      if (this.$route.params.tab) {
        switch (this.$route.params.tab.toLowerCase()) {
          case 'monthly':
            this.activeTab = 1;
            break;
          default:
            this.activeTab = 0;
        }
      }
    }
  }
}
</script>
