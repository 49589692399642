<template>
  <div class="p-field p-grid">
    <label class="p-col-fixed p-mt-2" for="org" style="width:150px">{{ _t('label_orgs') }}</label>
    <div class="p-col">
      <MultiSelectableBreadcrumb :singleSelection="false" @change="setOrgId"></MultiSelectableBreadcrumb>
    </div>
  </div>
  <!--  <div class="p-field p-grid">-->
  <!--    <label for="year" class="p-col-fixed" style="width:100px">Year*</label>-->
  <!--    <div class="p-col">-->
  <!--      <Calendar v-model="year" view="year" id="year" dateFormat="yy" placeholder="Select year" autocomplete="off" />-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="p-field p-grid">
    <label class="p-col-fixed" for="month" style="width:100px">{{ _t('label_month') }}*</label>
    <div class="p-col">
      <Calendar id="month" v-model="month" :placeholder="_t('select_month')" autocomplete="off" dateFormat="mm/yy"
                view="month"/>
    </div>
  </div>
  <div class="p-field p-grid">
    <label class="p-col-fixed" for="title" style="width:100px">{{ _t('specify_title') }}</label>
    <div class="p-col">
      <InputText id="title" v-model="title" :placeholder="_t('optional_title')" autocomplete="off"/>
    </div>
  </div>
  <Button :loading="loading" class="p-button-success" label="Download" @click="download"/>
  <Toast/>
</template>

<script>
import MultiSelectableBreadcrumb from "@/components/ixarma/MultiSelectableBreadcrumb";
import {getMonthlyReport} from "../../api/reports";
import moment from 'moment';

export default {
  name: 'report-monthly',
  components: {
    MultiSelectableBreadcrumb
  },
  data: () => ({
    orgIds: localStorage.getItem('multiOrgIds') ?? [{id: 1}],
    year: null,
    month: null,
    title: '',
    loading: false,
    monthNames: [],
  }),
  mounted() {
    this.monthNames = [this._t('JANUARY'), this._t('FEBRUARY'), this._t('MARCH'), this._t('APRIL'), this._t('MAY'), this._t('JUNE'),
      this._t('JULY'), this._t('AUGUST'), this._t('SEPTEMBER'), this._t('OCTOBER'), this._t('NOVEMBER'), this._t('DECEMBER')];
  },
  methods: {
    setOrgId: function (id) {
      this.orgIds = id
    },
    download: function () {
      let isValid = true;
      let orgs = [];
      try {
        orgs = JSON.parse(localStorage.getItem('multiOrgIds'));
      } catch (e) {
        orgs = [{id: 1}];
      }
      if (!orgs.length) {
        isValid = false;
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Organizations is required', life: 3000});
      }
      // if (!this.year) {
      //   isValid = false;
      //   this.$toast.add({severity:'error', summary: 'Error', detail:'Field \'Year\' can\'t be empty', life: 3000});
      // }
      if (!this.month) {
        isValid = false;
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Field \'Month\' can\'t be empty', life: 3000});
      }
      if (isValid) {
        this.loading = true;
        return getMonthlyReport(
            orgs,
            moment(this.month).year(),
            moment(this.month).format('MMMM').toUpperCase(),
            this.title
        )
            .then(
                (res) => {
                  if (res.status === 200) {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    const blob = new Blob([res.data], {type: 'xlsx'});
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    let headerLine = res.headers['content-disposition']
                    a.download = `${Date.now()}.xls`
                    if (headerLine) {
                      a.download = headerLine.split('=').pop().replace('"', '')
                    }
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.loading = false;
                  }
                },
                error => {
                  let data = error.response?.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: (data.reason ? ': ' + data.reason : 'Something went wrong'),
                    life: 3000
                  });
                }
            )
            .finally(() => {
              this.loading = false;
            });
      }
    }
  },
}
</script>
