import {LanguageEnum} from "@/enums/language.enum";
import {deutschPeriods, englishPeriods, frenchPeriods, italianPeriods} from "@/constants/constants";

const language = localStorage.getItem('lang')

export const CalendarPeriodSwitcher = () => {
    switch (language) {
        case LanguageEnum.ENGLISH:
            return englishPeriods;
        case LanguageEnum.GERMAN:
            return deutschPeriods;
        case LanguageEnum.FRENCH:
            return frenchPeriods;
        case LanguageEnum.ITALIAN:
            return italianPeriods;
        default:
            return englishPeriods;
    }
}
