import request from "@/api/request";
import {mapDuration} from "./alarmTable";

export function getMonthlyReport(orgs, year, month, name = '') {
    return request({
        url: `/reports/monthly`,
        responseType: 'blob',
        method: 'post',
        data: {
            allGeoRecur: false,
            month,
            year,
            orgs,
            name
        },
        timeout: 1200000
    })
}

export function getDetailedReport(format, query) {
    query.duration = mapDuration(query.duration)

    return request({
        url: format.toLowerCase() === 'pdf' ? `/reports/detailed-pdf` : `/reports/detailed-excel`,
        responseType: 'blob',
        method: 'post',
        data: query,
        timeout: 1200000

    })
}
