<template>
  <div class="extended-calendar" :class="orientation" id="extendedCalendar">
    <InputText v-model="title" @click="toggle = !toggle" :readonly="true"/>
    <Card :class="{ 'toggle': toggle }">
      <template #content>
        <div class="p-grid">
          <div class="p-col-12 p-text-right p-mb-0 p-pb-0">
            <Button icon="pi pi-trash" class="p-mr-2 p-button-outlined p-button-warning"
                    @click="clearDate(); toggle = !toggle"/>
            <Button icon="pi pi-times" class="p-mr-2 p-button-outlined p-button-danger" @click="toggle = !toggle"/>
          </div>
          <div class="p-col-4">
            <a v-for="day in periods.days" :key="day.value" href="#" @click.stop.prevent="setPeriod(day.name)" class="p-d-block">{{day.value}}</a>
          </div>
          <div class="p-col-4">
            <a v-for="week in periods.weeks" :key="week.value" href="#" @click.stop.prevent="setPeriod(week.name)" class="p-d-block">{{week.value}}</a>
          </div>
          <div class="p-col-4">
            <a v-for="quarter in periods.quarts" :key="quarter.value" href="#" @click.stop.prevent="setPeriod(quarter.name)" class="p-d-block">{{quarter.value}}</a>
          </div>
          <div class="p-col-12">
            <Calendar
                v-model="currentDate"
                :numberOfMonths="2"
                :inline="true"
                selectionMode="range"
                :showOtherMonths="true"
                @dateSelect="onDateSelect"
            ></Calendar>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import {CalendarPeriodSwitcher} from "@/service/helper/calendarPeriodSwitcher";

export default {
  name: 'ExtendedCalendar',
  props: {
    date: {
      required: true,
    },
    orientation: {
      type: String,
      default: () => 'right',
    },
  },
  data: () => ({
    toggle: false,
    periods: {},
  }),
  beforeMount() {
    this.periods = CalendarPeriodSwitcher()
  },
  methods: {
    clearDate() {
      this.currentDate = [null, null]
    },
    setPeriod(periodName = 'today') {
      switch (periodName) {
        case 'today':
          this.currentDate = [
            moment().startOf('days').toDate(),
            moment().endOf('days').toDate(),
          ];
          break;
        case 'yesterday':
          this.currentDate = [
            moment().subtract(1, 'days').startOf('days').toDate(),
            moment().subtract(1, 'days').endOf('days').toDate(),
          ];
          break;
        case 'last7days':
          this.currentDate = [
            moment().subtract(6, 'days').startOf('days').toDate(),
            moment().endOf('days').toDate(),
          ];
          break;
        case 'last28days':
          this.currentDate = [
            moment().subtract(27, 'days').startOf('days').toDate(),
            moment().endOf('days').toDate(),
          ];
          break;
        case 'thisWeek':
          this.currentDate = [
            moment().startOf('isoWeeks').toDate(),
            moment().endOf('isoWeeks').toDate(),
          ];
          break;
        case 'lastWeek':
          this.currentDate = [
            moment().startOf('isoWeeks').subtract(7, 'days').toDate(),
            moment().endOf('isoWeeks').subtract(7, 'days').toDate(),
          ];
          break;
        case 'thisMonth':
          this.currentDate = [
            moment().startOf('months').toDate(),
            moment().endOf('months').toDate(),
          ];
          break;
        case 'lastMonth':
          this.currentDate = [
            moment().subtract(1, 'months').startOf('month').toDate(),
            moment().subtract(1, 'months').endOf('month').toDate(),
          ];
          break;
        case 'thisQuarter':
          this.currentDate = [
            moment().startOf('quarters').toDate(),
            moment().endOf('quarters').toDate(),
          ];
          break;
        case 'lastQuarter':
          this.currentDate = [
            moment().subtract(1, 'quarters').startOf('quarters').toDate(),
            moment().subtract(1, 'quarters').endOf('quarters').toDate(),
          ];
          break;
        case 'thisYear':
          this.currentDate = [
            moment().startOf('years').toDate(),
            moment().endOf('years').toDate(),
          ];
          break;
      }
      this.toggle = false;
    },
    onDateSelect() {
      if (Array.isArray(this.currentDate) && this.currentDate.filter(i => i !== null).length === 1) {
        this.toggle = false;
      }
    }
  },
  computed: {
    currentDate: {
      get: function () {
        const dates = [];
        if (this.date?.start) dates.push(moment(this.date.start).toDate());
        if (this.date?.end) dates.push(moment(this.date.end).toDate());
        return dates;
      },
      set: function (val) {
        this.$emit('update:date', {
          ...this.date,
          start: val[0] ? moment(val[0]).startOf('days') : null,
          end: val[1] ? moment(val[1]).endOf('days') : null,
          option: 'RANGE',
          hours: 0,
        });
      },
    },
    title: function () {
      if (this.currentDate.filter(i => i !== null).length) {

        const dates = [moment(this.currentDate[0]).format('DD.MM.YYYY')];
        if (this.currentDate[1]) {
          dates.push(moment(this.currentDate[1]).format('DD.MM.YYYY'));
          if (moment(this.currentDate[0]).format('DD.MM.YYYY') === moment(this.currentDate[1]).format('DD.MM.YYYY')) {
            return moment(this.currentDate[0]).format('DD.MM.YYYY');
          }
        }
        return dates.join(' - ');
      }
      return this._t('label_select_dates');
    }
  }
}
</script>

<style lang="scss">
#extendedCalendar {
  max-width: 550px;
  position: relative;

  .p-datepicker table td > span {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-card {
    position: absolute;
    right: 0;
    margin: 0;
    width: 550px;
    border-top: 1px solid #e9ecef;
    z-index: 999;

    &:not(.toggle) {
      display: none;
    }

    .p-card-content {
      padding: 0;
    }
  }
}
</style>
