export function associateColor(color) {

    if (color === 'LIGHT_MAGENTA') {
        return 'magenta'
    }

    if (color === 'MAROON') {
        return 'red'
    }

    if (color === 'LIGHT_RED') {
        return 'red'
    }


    if (color === 'LIGHT_YELLOW') {
        return 'yellowgreen';
    }

    if (color === 'LIGHT_GREEN') {
        return 'darkgreen';
    }

    return !color ? 'darkgrey' : color.replace(/[^a-zA-Z ]/g, '')
        .toLowerCase()
        .replace('_', '')
}
